<template>
    <div>
        <Header title="订单详情" @back="onBack"></Header>
        <a-spin :spinning="loading">
            <div>
				<a-table :pagination="false" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 1800 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'info'">
							<div>会员昵称：{{ record.nickname }}</div>
							<div>会员手机号：{{ record.phone }}</div>
						</template>
						<template v-if="column.key === 'num'">
							{{ record.cinemaSeatsJsonVOS.length || 0 }}
						</template>
						<template v-if="column.key === 'showTime'">
							<div>{{ transDateTime(record.showStartTime) }}</div>
							至
							<div>{{ transDateTime(record.showEndTime) }}</div>
						</template>
						<template v-if="column.key === 'goods'">
							<div v-if="saleType === 1">
								<div style="display: flex;" v-for="item in record.oiList" :key="item.id">
									<a-image style="width: 60px;" :src="item.picUrl ? item.picUrl.split(',')[0] : ''">
									</a-image>
									<div style="margin-left: 10px;">
										{{ item.mainTitle }}
									</div>
								</div>
							</div>
							<div v-else-if="saleType === 2">
								<div class="ui-goods" v-for="item in record.oiList" :key="item.id">
									<div style="display: flex;align-items: flex-start;">
										<a-image style="width: 50px;border-radius: 4px;"
											:src="item.imgUrl"></a-image>
										<div style="margin-left: 8px;">
											<div style="font-size: 12px;">
												{{ item.mainTitle }}
											</div>
											<div style="margin-top: 2px;font-size: 14px;color: orange;">
												￥{{ item.price }}
												<span style="margin-left: 60px;color: #999;">x{{ item.num }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
						<template v-if="column.key === 'price'">
							<div v-for="(item, i) in record.oiList" :key="item.id">
								<div v-if="i > 0" style="margin: 10px 0;border-bottom: dashed 1px #999;"></div>
								<div>销售价：{{ item.price }}元</div>
								<div>市场价：{{ item.originPrice }}元</div>
								<div>成本价：{{ item.costPrice }}元</div>
							</div>
						</template>
						<template v-if="column.key === 'orderPrice'">
							<div v-if="saleType !== 3">订单总额：{{ record.totalFee }}元</div>
							<div v-if="saleType !== 3">快递：{{ record.deliveryFee }}元</div>
							<div>实付金额：{{ record.payment }}元</div>
						</template>
						<template v-if="column.key === 'orderInfo'">
							<div>订单编号：{{ record.orderNo }}</div>
							<div>支付编号：{{ record.outTradeNo }}</div>
							<div>下单时间：{{ transDateTime(record.createTime) }}</div>
						</template>
						<template v-if="column.key === 'type'">
							<div v-for="item in record.commissionRecord" :key="item.id">
								<div v-if="item.level !== 4">
									<div>
										<div style="color: rgb(112, 182, 3);" v-if="item.level === 1">
											自购返佣：{{ item.commission }}元</div>
										<div style="color: rgb(132, 0, 255);" v-if="item.level === 2">
											邀请人返佣：{{ item.commission }}元</div>
										<div style="color: rgb(252, 169, 89);" v-if="item.level === 3">
											店长返佣：{{ item.commission }}元</div>
									</div>
								</div>
							</div>
						</template>
						<template v-if="column.key === 'time'">
							<div v-for="item in record.commissionRecord" :key="item.id">
								<div v-if="item.level !== 4 && item.finishTime">
									<span :style="{ color: ['rgb(112, 182, 3)', 'rgb(132, 0, 255)', 'rgb(252, 169, 89)'][item.level - 1] }">{{ transDateTime(item.finishTime) }}</span>
								</div>
							</div>
						</template>
					</template>
				</a-table>
                <div style="margin: 30px 0;text-align: center;">
                    <a-button @click="onBack">返回</a-button>
                </div>
            </div>
        </a-spin>
    </div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import { distributorReportOrderDetail } from '@/service/modules/distribution.js';
	export default {
		components: {
			Icon,
			Header
		},
		props: {
			orderId: {
				type: Number,
				default: ''
			},
			saleType: {	// 1商品 2卖品 3影票
				type: Number,
				default: 0
			}
		},
		data(){
			return {
				loading: false,
				list: [],
				columns: [],
			}
		},
		created() {
			this.columns = this.transColums();
			this.getData();
		},
		methods: {
			onBack() {
				this.$emit('back');
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await distributorReportOrderDetail({
						page: 1,
						pageSize: 1,
						orderId: this.orderId,
						// saleType: this.saleType
					});
					this.loading = false;
					if (ret.code === 200) {
						this.list = [ret.data.detail];
					}
				} catch(e) {
					this.loading = false;
				}
			},
			transColums() {
				if (this.saleType === 1) {
					return [{
						title: '买家信息',
						key: 'info'
					}, {
						title: '商品信息',
						key: 'goods',
						width: 400
					}, {
						title: '商品单价',
						key: 'price'
					}, {
						title: '订单交易总额',
						key: 'orderPrice'
					}, {
						title: '订单信息',
						key: 'orderInfo'
					}, {
						title: '返佣信息',
						key: 'type'
					}, {
						title: '返佣发放时间',
						key: 'time'
					}];
				}
				if (this.saleType === 2) {
					return [{
						title: '买家信息',
						key: 'info'
					}, {
						title: '卖品信息',
						key: 'goods',
						width: 400
					}, {
						title: '订单交易总额',
						key: 'orderPrice'
					}, {
						title: '订单信息',
						key: 'orderInfo'
					}, {
						title: '返佣信息',
						key: 'type'
					}, {
						title: '返佣发放时间',
						key: 'time'
					}];
				}
				if (this.saleType === 3) {
					return [{
						title: '买家信息',
						key: 'info'
					}, {
						title: '订单信息',
						key: 'orderInfo'
					}, {
						title: '影片名称',
						dataIndex: 'filmName'
					}, {
						title: '数量',
						key: 'num'
					}, {
						title: '订单交易总额',
						key: 'orderPrice'
					}, {
						title: '放映时间',
						key: 'showTime'
					}, {
						title: '返佣信息',
						key: 'type'
					}, {
						title: '返佣发放时间',
						key: 'time'
					}];
				}
			},
		}
	}
</script>

<style scoped>
	p > span {
		color: rgba(0,0,0,.65);
	}
	.ui-goods {
			padding: 6px 10px;
			border-radius: 10px;
			border: solid 1px #EEE;
		}
		.ui-goods + .ui-goods {
			margin-top: 4px;
		}
</style>
