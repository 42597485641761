<template>
	<div v-show="!showDetail">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影院组织" name="organizationId">
						<a-select placeholder="请选择影院组织" v-model:value="formState.organizationId" style="width: 190px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" label="所属影院" name="cinemaId">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" style="width: 190px;" @change="getCinemaId">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id" > {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				<a-row v-show="showAll">
					<a-form-item class="ui-form__item" label="会员信息" name="userInfo">
						<a-input v-model:value="formState.userInfo" placeholder="请输入会员昵称\会员手机号"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="订单号" name="orderNo">
						<a-input placeholder="请输入订单号" v-model:value="formState.orderNo"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="订单类型" name="saleType">
						<a-select style="width: 190px;" placeholder="选择订单类型" v-model:value="formState.saleType">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option :value="1">商品</a-select-option>
							<a-select-option :value="2">卖品</a-select-option>
							<a-select-option :value="3">影票</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="平台发起提现" name="status">
						<a-select style="width: 190px;" placeholder="选择取票状态" v-model:value="formState.status">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option :value="2">已发起</a-select-option>
							<a-select-option :value="1">未发起</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="放映时间">
						<a-range-picker v-model:value="time" :showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }"/>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="销售时间">
						<a-range-picker v-model:value="payTime" :showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }" :disabledDate="disabledDate"/>
					</a-form-item>
				</a-row>
				
				<a-row>
					<a-col :span="18">
                        <span v-permission="['member_distributor_report_export']">
							<ExportReport type="distributorReport" :searchData="searchData"></ExportReport>
						</span>
						<a style="margin-left: 20px;" @click="showAll = !showAll">
							{{ showAll ? '收起' :'展开' }}
							<Icon v-show="!showAll" icon="DownOutlined"></Icon>
							<Icon v-show="showAll" icon="UpOutlined"></Icon>
						</a>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1700 }" tableLayout="auto">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.payTime, 1) }}
						</template>
						<template v-if="column.key === 'showTime'">
							{{ transDateTime(record.showStartTime, 1) }}
						</template>
						<template v-if="column.key === 'day'">
							星期{{ ['日', '一', '二', '三', '四', '五', '六'][moment(record.createTime *1000).day()] }}
						</template>
						<template v-if="column.key === 'saleType'">
							{{ ['商品', '卖品', '影票'][record.saleType - 1] }}订单
						</template>
						<template v-if="column.key === 'orderInfo'">
							<div>订单号：<span style="color: #999;">{{ record.orderNo }}</span></div>
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>用户昵称：{{ record.nickname || '--' }}</div>
							<div>手机号：{{ record.phone }}</div>
						</template>
						<template v-if="column.key === 'level'">
							<span v-if="record.level == 1" style="color:purple;">自购返佣</span>
							<span v-else-if="record.level == 2" style="color: #5e7e2d;">邀请人返佣</span>
							<span v-else-if="record.level == 3" style="color:blue;">团队长分佣</span>
						</template>
						<template v-if="column.key === 'isSubLedger'">
							{{ record.status === 2 ? '已发起' :'未发起' }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button type="link" @click="onDetail(record)">查看订单详情</a-button>
						</template>
					</template>
					<template #summary v-if="list && list.length">
						<a-table-summary-row>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell></a-table-summary-cell>
							<a-table-summary-cell>
								合计：{{ total.commissionSum || 0 }}
							</a-table-summary-cell>
						</a-table-summary-row>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
	<detail v-if="showDetail" :orderId="orderId" :saleType="saleType" @back="onBack"></detail>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import detail from './detail.vue';
	import { distributorReport } from '@/service/modules/distribution.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import ExportReport from '@/components/exportReport/exportReport.vue';

	export default {
		components: {
			Icon,
			ExportReport,
			detail
		},
		data() {
			return {
				loading: false,
				showAll: false,
				searchData: {},
				formState: {
					cinemaId: 0,
					saleType: 0,
					status: ''
				},
				time: [],
				payTime: [],
				list: [],
				total: {},
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
                columns: [{
					title: '销售日',
					key: 'createTime',
					width: 120
				}, {
                    title: '放映日',
                    key: 'showTime',
					width: 120
                }, {
                    title: '所属影院',
                    dataIndex: 'name'
                }, {
					title: '订单类型',
					key: 'saleType'
				}, {
                    title: '订单信息',
					key: 'orderInfo',
					width: 300
                }, {
					title: '会员信息',
					key: 'userInfo',
					width: 230
				}, {
					title: '分销等级',
					dataIndex: 'title',
					width: 100
				}, {
					title: '分销类型',
					key: 'level',
					width: 140
				}, {
					title: '分销金额(元)',
					dataIndex: 'commission',
					width: 120
				}, {
					title: '平台发起提现',
					key: 'isSubLedger',
					width: 120
				}, {
    			   title: '操作',
                   key: 'action',
				   fixed: 'right',
				 	width: 140
                }],
				organizationList: [],
				cinemaAllList: [],
				detail: {},
				showDetail: false,
				orderId: '',
				saleType: 0
			}
		},
		created(){
			// this.onSearch();
			this.getOrganizationList();
		},
		methods: {
			onBack() {
				this.showDetail = false;
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.saleType = this.searchData.saleType ? this.searchData.saleType : undefined;
				this.searchData.status = this.searchData.status != '' ? this.searchData.status : undefined;
				if (this.time && this.time.length) {
					this.searchData.showStartTime = this.moment(this.time[0]).unix();
					this.searchData.showEndTime = this.moment(this.time[1]).unix();
				}
				if (this.payTime && this.payTime.length) {
					this.searchData.payStartTime = this.moment(this.payTime[0]).unix();
					this.searchData.payEndTime = this.moment(this.payTime[1]).unix();
				}
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				this.cinemaAllList = [];
				this.time = [];
				this.payTime = [];
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				const postData = {
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData,
				}
				this.loading = true;
				try {
					let {data} = await distributorReport(postData);
					this.loading = false;
					this.total = data.list[0];
					this.list = data.list.splice(1);
					this.pagination.total = data.totalCount;
				} catch (error) {
					this.loading = false;
					console.log(error);
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				} 
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				// this.formState.cinemaId = 0;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onDetail(item) {
				this.orderId = item.id;
				this.saleType = item.saleType;
				this.showDetail = true;
			},
			getCinemaId(value){
				this.cinemaIds = value;
			},
			disabledDate(current) {
                return current && current >= this.moment().endOf('day');
            }
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
